



.about-modal .modal-header{
    color:#fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; 
    }
    

    .about-modal .modal-content{
        border-radius: 0!important;
        border:none;
    }

    .about-modal .modal-header h5 {
        width:90%;
        text-align: center;
    }
    
    .about-modal .modal-header button {
        width:10%;
    }
    .about-modal .modal-header h5{
        font-size: 18px;
        }
    
    .about-modal .modal-header .close{
        color:#8AC53F;
        opacity: 1;
        text-shadow: none;
        font-size: x-large;
    }
    
    .about-modal .loginbutton{
        background-color: #8AC53F;
        border:none;
        border-radius: 0;
        margin:30px 0 20px 0;
        box-shadow: none;
    color:white;

    }
    
    .about-modal .loginbutton:hover{
        background-color: rgb(100, 145, 43);
        border:none;
        border-radius: 0;
        border-color:rgb(100, 145, 43);
        box-shadow: none;
    }
    
    .about-modal .modal-body{
        padding:3rem 3rem 0 2rem;
    }
    
    .about-modal .modal-body .form-group{
        justify-content: center;
    }
    
    .about-modal .forgot-pass-login{
        color:#3E3F9B!important;
       cursor: pointer;
       font-weight: 500;
    }
    
    
    .about-modal .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0;
        border-top: none;
        border-bottom-right-radius: .3rem;
        border-bottom-left-radius: .3rem;
    }
    .about-modal .social-logins-login{
        display:flex;
        justify-content: space-around;
        border-top:1px solid #efefef;
        border-bottom:1px solid #efefef;
        padding:20px 0;
    }
    
    .about-modal .social-logins-login button {
        width:45%;
    }
    
    .about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="text"],
    .about-modal select.form-control {
      background: transparent;
      border: none;
      border-bottom: 1px solid #000000;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 0;
    }
    
    .about-modal input[type="email"]:focus, .about-modal input[type="password"]:focus, .about-modal input[type="name"]:focus,
    .about-modal select.form-control:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .login-with-google.btn-outline-primary{
        border-color:#3E3F9B;
        color:#3E3F9B;
        border-radius: 0;
    }

    .login-with-google.btn-outline-primary:hover{
        border-color:#3E3F9B;
        background-color:#3E3F9B;
        color:#fff;
    }

    .login-with-fb.btn.btn-primary{
        border-color:#3E3F9B;
        background-color:#3E3F9B;
        color:#fff;
        border-radius: 0;
    }

    .login-with-fb.btn.btn-primary:hover{
        border:1px solid #3E3F9B;
        background-color:#fff!important;
        color:#3E3F9B;
        filter: brightness(100%);
    }


    .email-icon-modal, .pass-icon-modal{
        position: absolute;
        top:12px;
        left:20px;
        color:#3E3F9B;
    }

    .eye-icon-modal{
        position: absolute;
        top:12px;
        right:25px;
        color:#8AC53F;
        
    }


.about-modal input[type="email"], .about-modal input[type="password"], .about-modal input[type="name"], .about-modal select.form-control, .about-modal .password-input{
    padding-left:40px;
}

.about-modal .form-control::placeholder {
    color:#CECECE
    ;

}

body { padding-right: 0 !important }