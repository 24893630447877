.footer-bg {
    background-color: #3E3F9B;
    padding: 20px 0;
    margin-top: 50px;
}

.footer-bg .footer-links {
    background-color: transparent;
    color: #fff;
    font-weight: 400;
    margin: 0 5px;
    border:none;
    text-align: center;
}


 .footer-bg .footer-links:hover, .footer-bg .footer-links:focus, .footer-bg .footer-links:visited, .footer-bg .footer-links:active, .footer-bg .footer-links:focus-within {
background-color: transparent;
border:none;
text-decoration: none;
color:#fff;
} 