.categories-in-dash {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.main-searchbutton {
  /* display: none; */
  background-color: #8ac53f !important;
  padding: 0 60px;
  border: none;
  box-shadow: none;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
}
/* .mainsearchinput:not(:placeholder-shown) + .main-searchbutton {
    display:block;
} */

.banner .mainsearchinput::placeholder {
  padding-left: 100px;
  padding-top: 25px;
}

.form-control::placeholder {
  /* font-size: 1.25rem;
    color: #b9bbb7!important;
    letter-spacing: 1px;
     */
  position: absolute;
  left: 0;
  white-space: nowrap;
  color: #b9bbb7 !important;
  word-break: break-all;
}

.no-results-div {
  min-height: 500px;
  width: 100%;
  padding: 20px;
  font-size: 1.4rem;
}

.no-results-span {
  text-align: center;
}

.clickrefresh {
  cursor: pointer;
  font-weight: 700;
}

.paginate {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.paginate a {
  padding: 1px 10px;
  border-radius: 2px;
  font-size: 25px;
}
.paginate a:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.disabled-button {
  cursor: default !important;
}
.disabled-button:hover {
  background: rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 1400px) {
  .dashboard-card .card-img {
    height: 220px;
  }
  .page-content .banner {
    height: 550px;
  }
}
.banner {
  position: relative;
}
.banner::after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* .gm-style-iw.gm-style-iw-c{
  padding-right:10px!important;
  padding-top:10px!important;
} */

.gm-ui-hover-effect {
  right: -3px !important;
}

.dash-map-view-list-view span:hover {
  color: #8ac53f;
}

.map-marker-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-bottom: 0;
}

.map-hover-popup:hover {
  cursor: pointer;
}

.map-hover-popup:hover p {
  color: #8ac53f;
}

.map-hover-popup img {
  max-height: 200px;
}
