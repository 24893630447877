.edit-own-post-menu{
width: calc(100% + 30px);
    height: 80px;
    margin-left: -15px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
}

.edit-own-post-menu .edit-button{
    background-color: #8AC53F!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    color:white;

}


.edit-button:active,.edit-button:visited, .edit-button:link, .edit-button:focus, .edit-button:focus-within{
    background-color:#8AC53F!important;
    border:none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    filter:brightness(100%);
    background: #8AC53F!important;
    color:white;

}

.edit-own-post-menu .out-of-service-button{
    background-color:  #E24A4A!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.edit-own-post-menu .in-service-button{
    background-color:  #8AC53F!important;
    border: none!important;
    padding: 10px 45px!important;
    border-radius: 0!important;
    box-shadow: none!important;
    color:white;

}


.buttonrow button{
    margin-right:20px;
}

.buttonrowtwo{
    display:flex;
    justify-content: flex-end;
}


.notlogged-in{
    width: 40%;
    padding: 100px 30px;
    text-align: center;
    background-color: rgb(138, 197, 63);
    /* height: 100%; */
    margin-top: 20vh;
    margin: 20vh auto;
    border-radius: 10px;
}