.my-map-container {
    
    height: 500px;
    overflow: hidden;
}
.autocomplete-container input {
    margin-bottom: 20px;
}
.location-search-input {
    width: 100%;
}
.map {
   
    max-width: 100%;
    height: 100%;
    max-height: 400px !important;
    overflow: hidden;
}

