.sc-user-input {
  min-height: 40px;
  margin: 0px;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  transition: background-color .2s ease,box-shadow .2s ease;
  border-top-style: solid;
  border-width: 1px;
}


.sc-user-input--text {
  width: 300px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: black;
  -webkit-font-smoothing: antialiased;
  max-height: 40px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  
}


.sc-user-input--text:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgba(86, 88, 103, 0.3);
  outline: none;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.send-button {
  background-color: #8AC53F;
  color: white;
  margin:8px;
  width: 70px;
  height: 30px;
  border: none;
}


.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  box-shadow: none;
  background-color: white;
  
}


