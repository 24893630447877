.renting-nav{
    width:100%;
    display:flex;
    padding-left:50px;
    padding-bottom: 5px;
    list-style: none;
    background-color: transparent;
    margin-top:50px;
   
}

.renting-nav span {
    margin-bottom: -1px;
    margin-right: 50px!important;
    cursor: pointer;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    font-weight: 500;
    font-size: 25px;
}

.renting-nav span.active {
    border-bottom: 4px solid #8AC53F;
    border-right: none!important;
    border-left: none!important;
    border-top: none!important;
    color: #8AC53F!important;
}

.rentingout-list-content, .renting-list-content{
    padding:30px;
}