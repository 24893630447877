.edit-post .form-control {
    border: 0 !important;
    border-bottom: 1px solid black !important;
    border-radius: 0 !important;
    box-shadow: none;
}
.edit-post .input-group-text {
    padding: 0 .75rem 0 0 !important;
    font-size: 2rem !important;
    font-weight: 600 !important;
    color: #8AC53F !important;
    text-align: left !important;
    background-color: #e9ecef00 !important; 
    border: 0 !important;
    border-radius: 0 !important;
    
} 
.edit-post .edit-post-btn button{
    background-color: #8AC53F !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
}
.edit-post .edit-post-submit-btn {
    background-color: #8AC53F !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important;
}
.edit-post .edit-post-submit-btn:active {
    background-color: #8AC53F !important;
    color: white !important;
    /* font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important; */
}
.edit-post label {
    color: #AAAAAA;
    font-size: 13px;
    font-weight: 400;
}
/* .edit-post .form-control {
    padding: 1.375rem .75rem;
} */
.edit-post .form-group {
    margin-bottom: 20px ;
}
.edit-post input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    /* // alternative to pointer-events, compatible with all browsers, just make it impossible to find */
    width: 1px;
    height: 1px;
  }
.edit-post .upload-file-label {
    cursor: pointer;
    background-color: #8AC53F;
    color: white;
    font-size: 16px;
    padding: 10px 25px;
}
.edit-post .submit-button  .btn-secondary:active, .edit-post .submit-button .edit-post-submit-btn:active {
    color: #fff;
    /* filter:brightness(85%); */
    background: #8AC53F !important;
}
.edit-post-submit-btn.submit-button.btn.btn-secondary:focus {
    color: #fff;
    filter:brightness(85%);
    background-color: #8AC53F !important;
}

.image-upload-container .upload-btn.upload.upload-images-btn, .image-upload-container .upload-btn.upload.upload-images-btn:visited{
    background-color: #8AC53F !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    filter:brightness(100%);
}

.image-upload-container .upload-reset-btn.upload.upload-images-btn, .image-upload-container .upload-reset-btn.upload.upload-images-btn:visited{
    background-color: #454642!important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    filter:brightness(100%);
}


.video-upload-container .upload-btn.upload.upload-images-btn{
    background-color: #8AC53F !important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    width: 100% !important;
}

.upload-images-button-container .upload-reset-btn.upload.upload-images-btn, .upload-images-button-container .upload-reset-btn.upload.upload-images-btn:visited{
    background-color: #8AC53F!important;
    color: white !important;
    font-size: 16px !important;
    border-radius: 0 !important;
    border: 0 !important;
    filter:brightness(100%);
    padding-left:25px;
    padding-right:25px;
}

.timedatepicker-cont .rdt input {
    text-align: center;
} 

.price-on-addons{
    position: absolute;
    top: 20px;
    right: 0;
    background-color: #8AC53F;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    padding:5px 15px;
    color:#fff;
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);

  }


  /*for addons section*/


  
.post-info-container{
    display:flex;
    border-bottom: 1px solid #efefef;
    padding:10px 0;
   
}

.post-info-container.main-one .right-side{
display: flex;

}

.post-info-container.main-one .right-side .right-side-one{
    width:70%
}


.post-info-container.main-one .right-side .right-side-two{
    width:30%
}

.post-info-container .left-side{
    width:10%;
}

.post-info-container .addons-side-left{
    width:25%;
    padding:10px;
}


.post-info-container .right-side{
    width:100%;
    padding:10px;
}

.post-info-container .left-side .left-side-pf{
    border-radius: 100%;
    width: 70px;
    height: 70px;
    -o-object-fit: cover;
       object-fit: cover;
  
    padding: 0;
}

/* .post-info-container .left-side .left-side-icon{
    width:100%;
    padding:20px;
} */

.post-info-container .post-title{
    margin-bottom: 10px;
}

.no-margin{
    margin:0!important;
}


.post-info-container .post-title-username{
    color:#B1B1B1;
    

}
.post-info-container .user-id{
    margin-left:20px;
}


.post-info-container .rates-container{
    display: flex;
    justify-content:space-between;
   
}

.post-info-container  .ratePrice{
    color:#000;
}

.post-info-container .start-date{
    color:#8AC53F;
    font-weight: 600;
}

.post-info-container .rental-date-container{
    display:flex;
    justify-content: space-between;
}
.add-ons-button-edit-post{
    color:#fff;
    padding:5px 20px;
    box-shadow: none;
    text-shadow: none;
    border: none;
    width: 100%;
    
}



  .rentedbyitself{
   padding-top:20px;
  }

  .form-control::placeholder{
      font-weight: 300;
      letter-spacing: 1px;
      color:rgba(0,0,0,0.1);
  }

  .change-button{
      background-color: #8AC53F;
      color:#fff;
      border-radius: 0;
      box-shadow: none;
      border:none;
      padding:5px 20px;
  }
  .final-addon-container{
    width: 850px;
  }

  .flex-term-slider .input-range {
    width: 90% !important;
  }
  @media only screen and (max-width: 1500px) {
        .final-addon-container{
            width: 550px;
        }
    }